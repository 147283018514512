import React, { useState, useEffect } from 'react';
import "./clickLogs.css"
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, Input } from '@mui/material';
import { toast } from 'react-toastify';
import account from 'src/_mock/account';
import TextareaAutosize from '@mui/material/TextareaAutosize';


export default function ClickLogs2() {
 
  const [selectedCategory, setSelectedCategory] = useState("Ecommerce");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedType, setSelectedType] = useState("Private");

  const [name, setName] = useState();
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  
  const { handleSubmit, control } = useForm();
  
  const URL = process.env.REACT_APP_PROD_API;

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(`${URL}/api/service-list`);
  //     if (response.status === 200) {
  //       // toast.success("Services fetched successfully!!");
  //       setData(response?.data);
  //       setCategoryOption(data.category);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching Data --->', error);
  //     toast.error("Error fetching filing hub soltion data!!");
  //   }
  // }

  // const handleTiming = (e) => {
  //   const inputTiming = e.target.value;
  //   // Split the input into days, hours, and minutes
  //   const [days, hours, minutes] = inputTiming.split('/').map(Number);

  //   // Calculate the total milliseconds
  //   const totalMilliseconds = (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60) * 1000;
  //   setTiming(totalMilliseconds);
  // }

  //const uniqueCategories = Object.values(data).map(item => item.category);
  const uniqueCategoriesSet = ["Ecommerce","BFSI","Banking","Casino","CPL","CPR","CPD","CPS","Gambling","Crypto","Survey"];
  const countryList=["India","Australia","Canada","Brazil","Vietnam","Russia"];
  const statusList=["active","expired","paused"];
  const campaignTypeList=["Private","Public"];
  // useEffect(() => {
  //   getData();
  // }, []);

  // const updateQunatityCharge = () => {

  //   let totalQuantity = 0;
  //   let totalCharges = 0;

  //   totalQuantity = quantity * maxExecutions;

  //   totalCharges = (quantity * maxExecutions) / 100;

  //   setTotalQuantity(totalQuantity);
    
  //   setTotalCharges(totalCharges);

  // }

  useEffect(() => {


   console.log("SELECTEDCOUNTRY__________________",selectedCountry);
  }, [selectedCountry]);

  const onSubmit = async () => {
    try {
      const data = {
        name: name,
        category:selectedCategory,
        country:selectedCountry,
        Tags:tags,
        description:description,
        url:url,
        payouts:{},
        image_url:"",
        status:selectedStatus,
        type:selectedType
      };
      const user = localStorage.getItem("user");
      const userData = JSON.parse(user);
      const accessToken = userData.data.access_token;
      const res = await axios.post(`${URL}/campaign/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      

      if (res.status === 200) {
        toast.success("Campagin Added successfully see Statistics!!");
      }



    } catch (error) {
      console.log("this is error While submitting the data--->", error);
      toast.error("Error While submitting the data!! See Console");
    }
  };
  return (
    <>
      <h1 className='text-center'>Add Campagin</h1>

      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <Input
              fullWidth
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Campaign name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="categoryDropdown"
              onChange={(e) => setSelectedCategory(e.target.value)}
              value={selectedCategory}
            >
              {uniqueCategoriesSet.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>


          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select
              id="countryDropdown"
              onChange={(e) => setSelectedCountry(e.target.value)}
              value={selectedCountry}
            >
              {countryList.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>



          <div className="form-group">
            <label htmlFor="tags">Tags</label>
            <Input
              fullWidth
              name="quantity"
              type='list'
              value={tags}
              placeholder='Enter Tags'
              onChange={(e) => setTags([e.target.value])}
            />
          </div>
        
          <div className="form-group">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="description">Description</label>
              <TextareaAutosize
                fullWidth
                name="description"
                value={description}
                placeholder="Enter Description"
                onChange={(e) => setDescription(e.target.value)}
                minRows={5} // You can adjust the number of rows as needed
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="url">URL</label>
            <Input
              fullWidth
              name="link"
              placeholder='Enter url'
              type='string'
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              id="statusDropdown"
              onChange={(e) => setSelectedStatus(e.target.value)}
              value={selectedStatus}
            >
              {statusList.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="type">Type</label>
            <select
              id="typeDropdown"
              onChange={(e) => setSelectedType(e.target.value)}
              value={selectedType}
            >
              {campaignTypeList.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>


          <div className='form-group submit d-flex justify-content-center  align-items-center'>
            <Button type="submit" variant="contained" color="success" >Submit</Button>
          </div>

        </form>




      </div>



    </>
  )
}
